import React from 'react'
import {createRoot} from 'react-dom/client'
import {createInertiaApp} from '@inertiajs/inertia-react'
import {InertiaProgress} from '@inertiajs/progress';
import axios from 'axios';
import Layout from '../components/Layout';
import {ThemeProvider} from "../components/ThemeProvider";

// @ts-ignore
const pages = import.meta.glob('../pages/**/*.tsx')

const forceScreenSize = (width, height) => {
    // @ts-ignore
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
        // Size window after open the app
        window.resizeTo(width, height)

        window.addEventListener('resize', () => {
            window.resizeTo(width, height)
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const csrfToken = (document.querySelector('meta[name=csrf-token]') as any).content;
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

    InertiaProgress.init();

    createInertiaApp({
        resolve: async name => {
            const page = (await pages[`../pages/${name}.tsx`]()).default;
            page.layout = page.layout || Layout

            return page
        },
        setup({el, App, props}) {
            const root = createRoot(el)
            forceScreenSize(500, 950)
            root.render(
                <ThemeProvider defaultTheme="system" storageKey="ui-theme">
                    <App {...props} />
                </ThemeProvider>
            )
        },
    })
});

