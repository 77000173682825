import * as React from "react";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../components/ui/sheet";
import {Button} from "./ui/button";

// @ts-ignore
import changelog from './../changelog.json';
import {Separator} from "./ui/separator";
import {ScrollArea} from "./ui/scroll-area";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "./ui/tabs";
import useActivity from "../hooks/useActivity";

interface Change {
    description: string;
    details: string;
    type: 'feature' | 'bug' | 'improvement' | 'chore';
}

interface ChangelogItemProps {
    version: string;
    date: string;
    changes: Change[];
}

const iconMap = {
    feature: '🚀',
    bug: '🐞',
    improvement: '🔧',
    chore: '🧹',
}

const Changelog: () => JSX.Element = () => {
    const {trackActivity} = useActivity()
    return (
        <Sheet onOpenChange={(open) => open ? trackActivity('open_changelog') : null}>
            <SheetTrigger asChild>
                <Button variant="link">
                    v{changelog.changes[0].version}<span className="hidden lg:inline-block">changelog & roadmap</span>
                </Button>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle className="font-display">Changelog & Roadmap</SheetTitle>
                    <SheetDescription>Keep an eye on changes, there will be some improvements here.</SheetDescription>
                </SheetHeader>
                <Tabs onValueChange={(value) => trackActivity('change_changelog_tab', {tab: value})}
                      defaultValue="changelog" className="w-full mt-4">
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="changelog">Changelog</TabsTrigger>
                        <TabsTrigger value="roadmap">Roadmap</TabsTrigger>
                    </TabsList>
                    <TabsContent value="changelog">
                        <div className="grid gap-4 py-4">
                            <ScrollArea className="h-[80svh]">
                                {changelog.changes.map((change: ChangelogItemProps, index) => (
                                    <>
                                        {index === 0 ? null : <Separator className="my-4"/>}
                                        <div key={change.version}>
                                            <div
                                                className="text-xl font-semibold flex items-center">
                                                {index === 0 ? (<span className="text-xs mr-2">🟢</span>) : null}
                                                v{change.version}</div>
                                            <div className="text-xs text-muted-foreground">{change.date}</div>
                                            <div className="grid gap-2 mt-4">
                                                {change.changes.map((change: Change) => (
                                                    <div key={change.description} className="mb-1">
                                                        <div className="flex items-center gap-2">
                                                            <div className="text-md">{iconMap[change.type]}</div>
                                                            <div
                                                                className="text-sm font-semibold">{change.description}</div>
                                                        </div>
                                                        <div className="text-sm ">{change.details}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </ScrollArea>
                        </div>
                    </TabsContent>
                    <TabsContent value="roadmap">
                        <ScrollArea className="h-[80svh]">
                            <ul className="[&>*]:py-2 list-disc px-4 text-sm mt-2">
                                <li className="text-green-800 dark:text-green-700 font-bold">☁ Get up and running. Setup
                                    server, get
                                    online.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">🛠 Get a bit of scaffold
                                    after initial
                                    prototyping.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">▶ Get replayability
                                    feature, when you get back,
                                    you know where you left.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">💡 Light/Dark mode. It.
                                    Must. Be.
                                    Night. Devs are
                                    nocturnal creatures.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">😊 Add a backlink to X so
                                    people can get in
                                    touch.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">💻 <span
                                    className="text-red-500 line-through">Make a chrome extension.</span> Made this a PWA
                                    instead. Works cross-platform, installs native-like.
                                </li>
                                <li className="text-green-800 dark:text-green-700 font-bold">😅 Get yourself a proper
                                    logo, will ya?
                                </li>
                                <li>🎶 Get more sounds by v0.4</li>
                                <li className="text-green-800 dark:text-green-700 font-bold">👓 SEO optimize. Get OG
                                    images and such.
                                </li>
                                <li>⏹ Have a "stop all" button where "continue" is when you first load the app.</li>
                                <li>🌎 Get more exposure. It's time to show this a bit more.</li>
                                <li>🦻 Collect more feedback. Find a better way to get feedback.</li>
                                <li>🧑‍💻 Make a UNIX CLI tool (linux/mac).</li>
                                <li>🍸 After all this, chill. You did enough. :)</li>
                            </ul>
                        </ScrollArea>
                    </TabsContent>
                </Tabs>

            </SheetContent>
        </Sheet>
    )
}

export default Changelog;
