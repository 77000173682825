import React from "react";
import {InertiaLink} from "@inertiajs/inertia-react";
import {Moon, Sun, Twitter} from "lucide-react"
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "./ui/dropdown-menu";
import {Button} from "./ui/button";
import {useTheme} from "./ThemeProvider";
import Changelog from "./Changelog";
import useActivity from "../hooks/useActivity";
import Logo from "./Logo";

const Layout: React.FC = (props) => {
    const {setTheme} = useTheme()
    const {trackActivity} = useActivity()

    return (
        <>
            <div className="min-h-full dark:bg-zinc-950 min-h-[100vh]">
                <nav className="shadow-sm dark:bg-zinc-950 dark:text-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between items-center h-16">
                            <div>
                                <div
                                    className="font-display border-transparent inline-flex items-center px-1 pt-1 border-b-2"
                                    aria-current="page"
                                >
                                    <Logo/>
                                </div>
                                <Changelog/>
                            </div>
                            <div className="flex items-center gap-4">
                                <a href="https://x.com/PetarVukmanovic"
                                   target="_blank"
                                   className="text-sm font-medium text-gray-900 dark:text-white hover:text-gray-900 dark:hover:text-white"
                                   onClick={() => {
                                       trackActivity("clicked_contact_button")
                                   }}
                                >
                                    Contact
                                </a>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="outline" size="icon">
                                            <Sun
                                                className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"/>
                                            <Moon
                                                className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"/>
                                            <span className="sr-only">Toggle theme</span>
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                        <DropdownMenuItem
                                            onClick={() => {
                                                setTheme("light")
                                                trackActivity('change_theme', {mode: "light"})
                                            }}>
                                            Light
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => {
                                            setTheme("dark")
                                            trackActivity('change_theme', {mode: "system"})
                                        }}>
                                            Dark
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => {
                                            setTheme("system")
                                            trackActivity('change_theme', {mode: "system"})
                                        }}>
                                            System
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="pb-10">
                    <main>
                        <div className="sm:px-6 lg:px-8">

                            <div className="px-4 py-8 sm:px-0">
                                <div className="bg-white dark:bg-zinc-950 rounded-lg p-3">
                                    {props.children}
                                    <div className="mt-10 text-xs font-medium text-center pb-20 max-w-4xl mx-auto">
                                        Here at <a href="https://codebeats.dev"
                                                   className="font-display text-sky-800 hover:text-sky-600">codebeats.dev</a>,
                                        we believe that the right soundscape can unlock productivity and enhance your
                                        coding experience. Tailored specifically for a software developer and a software
                                        engineer roles, Codebeats
                                        offers a curated collection of ambient sounds, instrumental music, and
                                        noise-canceling mixes
                                        designed to help you stay focused, reduce distractions, and enter a state of
                                        deep work.
                                        <br/>
                                        <br/>
                                        Whether you're debugging, writing code, or brainstorming the next big idea,
                                        Codebeats
                                        provides the perfect background atmosphere to help you get in the zone.
                                        Customizable
                                        soundtracks let you create your ideal focus environment, blending nature sounds,
                                        white
                                        noise, or smooth electronic beats to match your workflow. Music for programming
                                        should be easy.
                                        Music for development should be seamless. Music for coding should be Codebeats.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
};


export default page => <Layout>{page}</Layout>;