import React from 'react'
import axios, {AxiosResponse} from 'axios'

const ACTIVITY_URL = '/api/v1/activity'
const METHOD = 'post'

interface UseActivityReturn {
    trackActivity: (props: any) => Promise<boolean>
}

export default function useActivity() {
    async function trackActivity(name: string, properties = {}) {
        try {
            await axios[METHOD](ACTIVITY_URL, {
                activity: {
                    name,
                    properties
                }
            })
            return true
        } catch (e) {
            return false
        }
    }

    return {
        trackActivity
    }
}